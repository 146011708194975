import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Setting a Schedule for the Hardware WDR",
  "path": "/Frequently_Asked_Question/WebUI_WDR_Schedule/",
  "dateChanged": "2020-07-30",
  "author": "Mike Polinowski",
  "excerpt": "The hardware wdr mode can lead to a blurry image. This is why I found it is best to deactivate the mode after sunset. Is there a way to automate this?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Setting a Schedule for the Hardware WDR' dateChanged='2020-07-30' author='Mike Polinowski' tag='INSTAR IP Camera' description='The hardware wdr mode can lead to a blurry image. This is why I found it is best to deactivate the mode after sunset. Is there a way to automate this?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WebUI_WDR_Schedule/' locationFR='/fr/Frequently_Asked_Question/WebUI_WDR_Schedule/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`Q`}</strong>{`: During the day I need to activate the `}<Link to="/Web_User_Interface/1080p_Series/Multimedia/Image/" mdxType="Link">{`Hardware WDR Mode`}</Link>{` to cope with the high dynamic range of sunlit and shadow areas in the video image. But during the night, especially when there are strong light sources in the background (cars, street lights, etc) this mode leads to a blurry image. This is why I found it is best to deactivate the mode after sunset. Is there a way to automate this?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/66ff616b42f9923eef80f807238e1d97/45662/Hardware_WDR_Mode_Schedule_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABz0lEQVQY0x3OTU+SAQDAcS7NuWQuDCF7iJdas5gzNWDJM0pJWVOoBs7mC2CUSKVJqFkLN3g2xDBZZi+GSGtKM6ZbrZhrc20eWh6cnDp0an2E1v3f8gv89pO1iOe5F7nPdCLF42SK3Ooab1bekcm9paX5HE2na2k0mdEKWnwDfhKzKdbza3z5usVuqcTO3h7BYJCqinJ0mhpkbVYbszNpFp69YmkpRzKdJvooylRMwulox2SsxVh3BkGp5kF4jMxyjk8fihQ2PzP/coH4tER3bzfVlXIEtRJZp2gjFp3ieq+XybEJgreG8TjdREbHcdrtNJ0woNcbOHpIwd2bN/D39DDo9TH+cBLHRQeey1fo7LiESl6B5j/oFEXsYjM+dxd97i4CoSFGwxHC4QnsFss+eEwjcFyt5pReh6XOiOuCDdfVDgZHQgTuDCNarajkBxFUSmT2hkZsZhOhQIBMdpmVQoGYFOfp83lazSYaDHq0Ot3+8KRQg81ylsziC0q/fpLfeM/qep7+/msoyss4Ul2FbMjvR4pLzKXTfCxuki9sMDP3hNfZLGJ9PXUGHa3tbXhcLkZuh0gkJba/f+P33z8Ut7fY+bHLgLePyrIDqA4r+AeSof+xaY70DwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/66ff616b42f9923eef80f807238e1d97/e4706/Hardware_WDR_Mode_Schedule_01.avif 230w", "/en/static/66ff616b42f9923eef80f807238e1d97/d1af7/Hardware_WDR_Mode_Schedule_01.avif 460w", "/en/static/66ff616b42f9923eef80f807238e1d97/7f308/Hardware_WDR_Mode_Schedule_01.avif 920w", "/en/static/66ff616b42f9923eef80f807238e1d97/e1c99/Hardware_WDR_Mode_Schedule_01.avif 1380w", "/en/static/66ff616b42f9923eef80f807238e1d97/ac5cb/Hardware_WDR_Mode_Schedule_01.avif 1410w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/66ff616b42f9923eef80f807238e1d97/a0b58/Hardware_WDR_Mode_Schedule_01.webp 230w", "/en/static/66ff616b42f9923eef80f807238e1d97/bc10c/Hardware_WDR_Mode_Schedule_01.webp 460w", "/en/static/66ff616b42f9923eef80f807238e1d97/966d8/Hardware_WDR_Mode_Schedule_01.webp 920w", "/en/static/66ff616b42f9923eef80f807238e1d97/445df/Hardware_WDR_Mode_Schedule_01.webp 1380w", "/en/static/66ff616b42f9923eef80f807238e1d97/e97dc/Hardware_WDR_Mode_Schedule_01.webp 1410w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/66ff616b42f9923eef80f807238e1d97/81c8e/Hardware_WDR_Mode_Schedule_01.png 230w", "/en/static/66ff616b42f9923eef80f807238e1d97/08a84/Hardware_WDR_Mode_Schedule_01.png 460w", "/en/static/66ff616b42f9923eef80f807238e1d97/c0255/Hardware_WDR_Mode_Schedule_01.png 920w", "/en/static/66ff616b42f9923eef80f807238e1d97/b1001/Hardware_WDR_Mode_Schedule_01.png 1380w", "/en/static/66ff616b42f9923eef80f807238e1d97/45662/Hardware_WDR_Mode_Schedule_01.png 1410w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/66ff616b42f9923eef80f807238e1d97/c0255/Hardware_WDR_Mode_Schedule_01.png",
              "alt": "Hardware-WDR-Zeitplan für Full-HD-Kameras",
              "title": "Hardware-WDR-Zeitplan für Full-HD-Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`A`}</strong>{`: The WebUI update `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`3.0 (321)`}</code>{` added a schedule to automatically switch between hardware and software WDR mode. To do this, go to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Multimedia/Image/"
      }}>{`Multimedia/Image`}</a>{` menu and set the time window in which the camera should activate hardware WDR.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "760px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/69aa912483f4f1d1df0b25aaee61c86b/3c051/Hardware_WDR_Mode_Schedule_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.78260869565219%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACP0lEQVQ4y4XSW0/UQBQH8H40Frbddu63dqadTjtbIsslAvJgjEjiogQMxgcTvwA+mCigDyz6oC/6xUx32eW6+st5aNqc/M+cTtBdOeYvL+juOdv7Cp+f956dTSp6+uWh+pwfjPjeN/7iNNr4EJCt9/7tT3d4Wb/5YV6P5PBC7Y/mVfpqVB9dFgejR+9+6d2PQXexk+tUpynB0OY6lYwRyCm6WToVJpOSE06RK4vaWc5IL+oGYdRbdtnjvlx1bLOvCi2ZkELeKpWmaZpJpaqqXmkNmmYZIRxEUZTnxhZ5aQujNWeM38PGOOdCCEqp93VduSRJgjAMnXNN0/j2Xa3nMMbMngvrTH8DJHGbnI6pMThFCKGUMsYwxvA2ABGROYQgiOMYQriwsLB4G5hKkuTOp06nA0Hb1SYXRVGPlWWppuSUukMKmdeq2WnHDsPQWuucq8asteIhUkoAQBiGEMJuN4QIXyc754qiyPPcGDPZ6v1mhFAcxwihHkBY5BCAq2TvvTGGUvpg56xfSikEl8apZvtq25Mze++ttXq+bEbnyg0AGP/n/lhVVc45/X+Zzossd2AydlmW3vssy/4x8zXOhHGqv3217abfr6vK6MzMv1+zMlobneksbZOjKGIiRZhiKuYGc8Yp4RRzzogQhDOqFIAgQEn0fQh+H4s/h8lmRTG/eUHaKyIZlmtDOzzV+2d+7eBkKfpE0pMelu2Zu0tbFX7iyU4NM4ERJhN4BkGsSubWqVvnqhrEySqiA4CSMPoLySOJmmSpNvwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/69aa912483f4f1d1df0b25aaee61c86b/e4706/Hardware_WDR_Mode_Schedule_02.avif 230w", "/en/static/69aa912483f4f1d1df0b25aaee61c86b/d1af7/Hardware_WDR_Mode_Schedule_02.avif 460w", "/en/static/69aa912483f4f1d1df0b25aaee61c86b/83f21/Hardware_WDR_Mode_Schedule_02.avif 760w"],
              "sizes": "(max-width: 760px) 100vw, 760px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/69aa912483f4f1d1df0b25aaee61c86b/a0b58/Hardware_WDR_Mode_Schedule_02.webp 230w", "/en/static/69aa912483f4f1d1df0b25aaee61c86b/bc10c/Hardware_WDR_Mode_Schedule_02.webp 460w", "/en/static/69aa912483f4f1d1df0b25aaee61c86b/9046c/Hardware_WDR_Mode_Schedule_02.webp 760w"],
              "sizes": "(max-width: 760px) 100vw, 760px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/69aa912483f4f1d1df0b25aaee61c86b/81c8e/Hardware_WDR_Mode_Schedule_02.png 230w", "/en/static/69aa912483f4f1d1df0b25aaee61c86b/08a84/Hardware_WDR_Mode_Schedule_02.png 460w", "/en/static/69aa912483f4f1d1df0b25aaee61c86b/3c051/Hardware_WDR_Mode_Schedule_02.png 760w"],
              "sizes": "(max-width: 760px) 100vw, 760px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/69aa912483f4f1d1df0b25aaee61c86b/3c051/Hardware_WDR_Mode_Schedule_02.png",
              "alt": "Hardware-WDR-Zeitplan für Full-HD-Kameras",
              "title": "Hardware-WDR-Zeitplan für Full-HD-Kameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      